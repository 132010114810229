<template>
    <div class="section message">
        <div class="container">
            <div class="message-text">
                {{$route.params.message}}
            </div>
            <div class="btn-container">
                <button
                        class="btn btn-success"
                        type="button"
                        @click="onClickButton"
                >
                    На главную
                </button>
            </div>
        </div>
    </div>

</template>

<script>
    import {homePath} from "@/router/home"
    import {LoginModalProperties} from "@/utils/modalsProperties"
    import LoginModal from "@/components/homePage/LoginModal"

    export default {
        name: "ConfirmMessageTab",
        methods: {
            async onClickButton() {
                await this.$router.push(homePath)
                this.$modal.show(
                    LoginModal,
                    null,
                    LoginModalProperties
                )
            },
        }
    }
</script>

<style scoped lang="less">
    @import '../assets/less/variables.less';

    .section {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .container {
        display: flex;
        flex-direction: column;
        border-radius: @br;
        background-color: @bg-color;
        padding: 24px 40px;
        margin: 40px;
        min-width: 200px;
        max-width: 520px;
        min-height: 100px;
        max-height: 300px;
    }
    .message {
        text-align: center;
    }
    .message-text {
        overflow-wrap: break-word;
        text-align: center;
        color: white;
        font-size: 18px;
        margin-bottom: 32px;
    }

    .btn-container {
        align-self: center;
    }

</style>